import { Grid, makeStyles } from "@material-ui/core"
import { Pagination as MaterialPagination } from "@material-ui/lab"
import animateScrollTo from "animated-scroll-to"
import React from "react"
import { connectPagination } from "react-instantsearch-dom"

const useStyles = makeStyles((theme) => ({
  paginationWrapper: {
    padding: `${theme.spacing(5)}px 0`,
    "& ul": {
      justifyContent: "center",
    },
  },
  paginationEmptySpace: {
    paddingTop: theme.spacing(5),
  },
}))

export const CustomPagination = connectPagination(({ currentRefinement, nbPages, refine }) => {
  const classes = useStyles()

  const handleChange = (event, value) => {
    event.preventDefault()
    refine(value)
    animateScrollTo(0, { speed: 0 })
  }

  if (nbPages > 1) {
    return (
      <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <MaterialPagination
            count={nbPages}
            className={classes.paginationWrapper}
            page={currentRefinement}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    )
  } else {
    return <div className={classes.paginationEmptySpace} />
  }
})
