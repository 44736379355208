import { makeStyles, Paper, Typography } from "@material-ui/core"
import StarIcon from "@material-ui/icons/Star"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"

const useStyles = makeStyles(() => ({
  anchor: {
    textDecoration: "none",
  },
  container: {
    height: "329px",
    boxShadow: "0 1px 4px 0 rgba(157, 157, 157, 0.5)",
  },
  content: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "329px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    color: "#fff",
    padding: "16px 16px 61px 16px",
    position: "relative",
  },
  ctaTitle: {
    fontFamily: "proxima-nova",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "1",
    letterSpacing: "-0.03188rem",
    textTransform: "uppercase",
  },
  ctaSubtitle: {
    fontFamily: "proxima-nova",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: "-0.03188rem",
    marginBottom: "1rem",
  },
  starWrapper: {
    display: "flex",
    marginBottom: "1rem",
  },
  starIcon: {
    color: "#FF8B01",
    width: "0.9rem",
    height: "0.9rem",
    margin: "0 0.15rem 0 0.15rem",
  },
  ctaButton: {
    background: "#2A7DCF",
    padding: "12px 24px",
    borderRadius: "2px",
    fontFamily: "proxima-nova",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    position: "absolute",
    bottom: "16px",
  },
}))

const ctaData = [
  {
    imageUrl: "/assets/CTA_recipe_img_oven_01_opacity.webp",
    title: "Precision™ Oven",
    subtitle: "A combi oven, designed for your kitchen.",
    url: "https://anovaculinary.com/products/anova-precision-oven",
  },
  {
    imageUrl: "/assets/CTA_recipe_img_accessories_01_opacity.webp",
    title: "Precision® Accessories",
    subtitle: "Experience next-level sous vide cooking.",
    url: "https://anovaculinary.com/collections/accessories",
  },
  {
    imageUrl: "/assets/CTA_recipe_img_cookers_01_opacity.webp",
    title: "Precision® Cookers",
    subtitle: "Edge-to-edge perfection. No skill required.",
    url: "https://anovaculinary.com/pages/find-your-anova-precision-cooker",
  },
  {
    imageUrl: "/assets/CTA_recipe_img_cookers_02_opacity.webp",
    title: "Precision® Cookers",
    subtitle: "Perfect results in a few simple steps.",
    url: "https://anovaculinary.com/pages/find-your-anova-precision-cooker",
  },
  {
    imageUrl: "/assets/CTA_recipe_img_cookers_03_opacity.webp",
    title: "Precision® Cookers",
    subtitle: "Sous vide made easier than ever.",
    url: "https://anovaculinary.com/pages/find-your-anova-precision-cooker",
  },
  {
    imageUrl: "/assets/CTA_recipe_img_generic_01_opacity.webp",
    title: "Anova",
    subtitle: "Pro-level cooking techniques made simple.",
    url: "https://anovaculinary.com/",
  },
]

interface CtaDataItem {
  imageUrl: string
  title: string
  subtitle: string
  url: string
}

const DynamicHitsShopifyCTA = (): JSX.Element => {
  const [randomCTA, setRandomCTA] = useState<number | null>(null)
  const [eventSent, setEventSent] = useState(false)
  const selectedCTARef = useRef<CtaDataItem | null>(null)
  const isMountedRef = useRef(true)
  const classes = useStyles()
  const router = useRouter()

  useEffect(() => {
    isMountedRef.current = true

    const currentPageUrl = window.location.origin + router.asPath

    const analyticsViewRecipeBacklink = () => {
      if (selectedCTARef.current) {
        const { title, url } = selectedCTARef.current
        window.gtag("event", "view_recipe_backlink", {
          send_to: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_ID,
          property: "Oven Recipe Site",
          page: "Search Results Page",
          variant_id: `CTA Version ${randomCTA + 1}`,
          title: title,
          page_url: currentPageUrl,
          link_url: url,
        })
        setEventSent(true)
      }
    }

    if (!eventSent) {
      if (randomCTA === null || randomCTA < 0 || randomCTA >= ctaData.length) {
        setRandomCTA(Math.floor(Math.random() * ctaData.length))
      }
      selectedCTARef.current = ctaData[randomCTA]
      analyticsViewRecipeBacklink()
    }

    return () => {
      isMountedRef.current = false
    }
  }, [randomCTA, router, eventSent])

  const handleClick = () => {
    if (selectedCTARef.current) {
      const { title, url } = selectedCTARef.current
      window.gtag("event", "click_recipe_backlink", {
        send_to: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_ID,
        property: "Oven Recipe Site",
        page: "Homepage",
        variant_id: `CTA Version ${randomCTA + 1}`,
        title: title,
        page_url: window.location.href,
        link_url: url,
      })
    }
  }

  if (randomCTA === null || randomCTA < 0 || randomCTA >= ctaData.length) {
    return null
  }

  const { imageUrl, title, subtitle, url } = ctaData[randomCTA]

  return (
    <a className={classes.anchor} href={url} title={title} rel="noreferrer" target="_blank" onClick={handleClick}>
      <Paper className={`${classes.container} ${classes.content}`} style={{ backgroundImage: `url(${imageUrl})` }}>
        <Typography className={classes.ctaTitle} component="h4">
          {title}
        </Typography>
        <Typography className={classes.ctaSubtitle} component="p">
          {subtitle}
        </Typography>
        <div className={classes.starWrapper}>
          {Array.from({ length: 5 }).map((_, index) => (
            <StarIcon key={index} className={classes.starIcon} />
          ))}
        </div>
        <span className={classes.ctaButton}>Shop now</span>
      </Paper>
    </a>
  )
}

export const CustomHitsShopifyCTA = (): JSX.Element => {
  return <DynamicHitsShopifyCTA />
}
