import { SvgIcon } from "@material-ui/core"

// 24 24 ?
export const CommentIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M8,21 L8,18 L4,18 C2.8954305,18 2,17.1045695 2,16 L2,5 C2,3.89 2.9,3 4,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,16 C22,17.1045695 21.1045695,18 20,18 L13.9,18 L10.2,21.71 C10,21.9 9.75,22 9.5,22 L9,22 C8.44771525,22 8,21.5522847 8,21 Z" />
    </SvgIcon>
  )
}
